
import React, { useLayoutEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';


import Autocomplete from "@material-ui/lab/Autocomplete";

import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import 'devextreme/data/odata/store';
import "devextreme/dist/css/dx.material.orange.light.css"
import Swal from 'sweetalert2';

import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    GroupPanel,
    Lookup,
    Export,
    ColumnFixing,
    Editing
    
} from 'devextreme-react/data-grid';
import {
    EmptyItem
} from 'devextreme-react/form';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import "./map.css";
import 'react-dropzone-uploader/dist/styles.css'

import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import moment from 'moment';
import { name } from "file-loader";

import Percent from "./Percent";


const curFormat = {
    type: 'currency',
    precision: 2,
  };

const BOEList = () => {
    const optionYesNo = [{
        ID: 'Y',
        Name: 'Yes',
      }, {
        ID: 'N',
        Name: 'No',
      }]
    const [dataList, setdataList] = useState([]);
    
    const [object, setobject] = useState([]);
        
    // const object = [
    //     { value1: 1, value2: 1, name: "2020" },
    //     { value1: 1.04, value2: 1.025, name: "2021" },
    //     { value1: 1.05, value2: 1.03, name: "2022" }
    // ]
    const exportFormats = ['xlsx'];
    
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    
    const [perShow, setperShow] = useState(false);
    const [pradd, setPradd] = useState("");
    const [prarea, setPrarea] = useState("");
    const [prID, setPrID] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const modClose = () => setModalShow(false);
    
    const performClose = () => setperShow(false);
    const performCloseRefresh = () => {
        
        setperShow(false)

        window.location.reload(true)
            
    
    
    };
    const performShow = () => setperShow(true);

    const [myOptions, setMyOptions] = useState([]);

    

   

       
    const [infoDetail, setDetail] = useState([]);

    const getInfo = (data) => {
        console.log(data)
        let value1 = data.trend_building_value
        let value2 = data.trend_bpp_value
            object.map(function(obj){
                value2=value2*obj.value2  
                value1=value1*obj.value1 
                                                                                                           
            })
        data.total=value1+value2
        setDetail(data)
        setModalShow(true)        

    };

    const getapiId = (refc) => {
        const element = document.getElementsByName('search')[0].value.split(':')
        if(element[1]){
            const pid=element[1].replaceAll(/[{()}[\]]/g,'');
            setPrID(pid);
            fetch("https://larpapi.zovaya.net/api/property/"+pid)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                console.log(res[0].propertyaddress);
                setPradd(res[0].propertyaddress);            
                setPrarea(res[0].areasf);           
            });
        }

    };
    const handleSubmit = (event)=> {
        event.preventDefault();
        const propertyid = prID;
        const traveler_loc = event.target.traveler_loc.value;        
        const boetype = event.target.boetype.value;
        const acct_loc = event.target.acct_loc.value;
        const name = event.target.name.value;
        const building_iso_code = event.target.building_iso_code.value;
        const stories = event.target.stories.value;
        const occupancy = event.target.occupancy.value;
        const sprinkler_prot = event.target.sprinkler_prot.value;
        const year_built = event.target.year_built.value;
        const trend_building_value = event.target.trend_building_value.value;
        const trend_bpp_value = event.target.trend_bpp_value.value;
        const edp_value = event.target.edp_value.value;
        const misc_value = event.target.misc_value.value;
        const me_value = event.target.me_value.value;        
        const vehicle_value = event.target.vehicle_value.value;
            axios.post("https://larpapi.zovaya.net/api/boeadd", {
            propertyid:propertyid,
            boetype:boetype,
            acct_loc:acct_loc,
            traveler_loc:traveler_loc,
            name:name,
            building_iso_code:building_iso_code,
            stories:stories,
            occupancy:occupancy,
            sprinkler_prot:sprinkler_prot,
            year_built:year_built,
            trend_building_value:trend_building_value,
            trend_bpp_value:trend_bpp_value,
            edp_value:edp_value,
            vehicle_value:vehicle_value,
            misc_value:misc_value,
            me_value:me_value
        })
        .then((response) => {
                setShow(false)
                axios.get("https://larpapi.zovaya.net/api/boelist",
                {
                    headers:{
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                    }
                }).then(resp => {
                    if (resp.status === 200) {
                        setdataList(resp.data);
                    }
                })
                    Swal.fire(
                        'Done ',
                        'Added Successfully!',
                        'success'
                    )
            })               
            .catch((error) => {
                console.log(error);
                Swal.fire(
                    'Failed ',
                    'Insert Error',
                    'error'
                    )
            });
    };
    const getDataFromAPI = () => {
        console.log("Options Fetched from API"); 

        
    };

    useLayoutEffect(() => {
        console.log('Test');
        axios.get("https://larpapi.zovaya.net/api/boelist",{
        
                headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
            }
        ).then(resp => {
            if (resp.status === 200) {
                setdataList(resp.data)
            }
        })

        axios.get("https://larpapi.zovaya.net/api/percent/boe",{
        
                headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
            }
        ).then(resp => {
            if (resp.status === 200) {
                console.log(resp.data)
                setobject(resp.data)
            }
        })

        fetch("https://larpapi.zovaya.net/api/propertylease")
            .then((response) => {
            return response.json();
            })
            .then((res) => {
            console.log(res);
            for (var i = 0; i < res.length; i++) {
                myOptions.push(res[i].propertyaddress+(res[i].city?', '+res[i].city:'')+(res[i].state?', '+res[i].state:'')+(res[i].zip?', '+res[i].zip:'')+ ', Id :{'+res[i].propertyid+'}');
            }
            setMyOptions(myOptions);
            });
    }, []);

    

    

    const onSaving = React.useCallback((e) => {
        var change = e.changes[0];
        console.log(e.changes)

        if (change.type==="remove") {
            e.promise = axios.put("https://larpapi.zovaya.net/api/boedel", { boeid: change.key })
                .then(resp => {
                    axios.get("https://larpapi.zovaya.net/api/boelist",
                    {
                        headers:{
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                        }
                    }).then(resp => {
                        if (resp.status === 200) {
                            setdataList(resp.data);
                        }
                    })
                });
        }
        else if (change) {
            e.promise = axios.put("https://larpapi.zovaya.net/api/boegrid", { boeid: change.key, values: change.data })
                .then(resp => {
                    // no nothing
                });
        }
    }, []);
    const onRowClick = ((e) => {
        
        if(e.data.hasOwnProperty('key')){            
        
        }else{
            getInfo(e.data)
        }
    });

    const onExporting = ((e) => {
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Data');
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
                customizeCell: ({ gridCell, excelCell }) => {
                    if (gridCell.rowType === 'data') {
                        if (gridCell.column.dataField === 'Total_value') {
                            let Tr_b = gridCell.data.trend_building_value
                            let Tr_bb = gridCell.data.trend_bpp_value
                            
                            object.map(function(obj){
                                Tr_bb=Tr_bb*obj.value2  
                                Tr_b=Tr_b*obj.value1                                             
                            })

                            let Total_value = Tr_b+Tr_bb+gridCell.data.edp_value+gridCell.data.misc_value+gridCell.data.me_value+gridCell.data.vehicle_value
                            excelCell.value =Total_value
                        }
                        object.map(function(objects){
                            if (gridCell.column.dataField === objects.year+'trend_building_value') {
                                let Tr_b = gridCell.data.trend_building_value
                                
                                object.map(function(obj){
                                    if(objects.year >= obj.year){ Tr_b=Tr_b*obj.value1}                                                
                                })
                                excelCell.value =Tr_b

                            }
                            if (gridCell.column.dataField === objects.year+'trend_bpp_value') {
                                let Tr_bb = gridCell.data.trend_bpp_value
                                
                                object.map(function(obj){
                                    if(objects.year >= obj.year){ Tr_bb=Tr_bb*obj.value2}                                                
                                })
                                excelCell.value =Tr_bb

                            }


                        })
                    }
                },
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'BOEData.xlsx');
                });
            });
        }
        
        else if (e.format === 'pdf') {
            const doc = new jsPDF();
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component
            }).then(() => {
                doc.save('BOEData.pdf');
            })
        };
    });
    let inputRef;

    return(
        <div>
            <div>

<React.Fragment>
    <div className="gr-buttons boe-buttons">
        
                    <button className="btn btn-year " onClick={performShow} title="" >
                    % / Year
                    </button>
                
                <a onClick={handleShow} title="Add New" className="btn btn-add">
                        Add New
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_221_3706)">
                            <path d="M5.4496 14.9649C6.85135 16.3362 9.09013 16.3418 10.4987 14.9775L13.1533 12.5244C16.0289 9.68707 16.06 5.05575 13.2226 2.18013C10.3852 -0.695499 5.75395 -0.726561 2.87832 2.11081C2.85507 2.13375 2.83195 2.15688 2.80901 2.18013C-0.0473359 5.03691 -0.0469608 9.66831 2.80982 12.5247C2.81573 12.5306 2.82166 12.5365 2.8276 12.5424L5.4496 14.9649ZM3.74926 3.12041C6.08132 0.778064 9.87066 0.76972 12.213 3.10178C14.5554 5.43385 14.5637 9.22319 12.2316 11.5655L9.58238 14.014C8.67751 14.8656 7.26445 14.8603 6.36591 14.002L3.74926 11.5842C1.41207 9.24697 1.41204 5.45766 3.7492 3.12044C3.74923 3.12044 3.74926 3.12041 3.74926 3.12041ZM4.65629 7.35228C4.65629 6.98503 4.95401 6.68731 5.32126 6.68731H7.31616V4.69241C7.31616 4.32516 7.61388 4.02744 7.98113 4.02744C8.34838 4.02744 8.6461 4.32516 8.6461 4.69241V6.68731H10.641C11.0083 6.68731 11.306 6.98503 11.306 7.35228C11.306 7.71953 11.0083 8.01725 10.641 8.01725H8.6461V10.0122C8.6461 10.3794 8.34838 10.6771 7.98113 10.6771C7.61388 10.6771 7.31616 10.3794 7.31616 10.0122V8.01725H5.32126C4.95401 8.01725 4.65629 7.71953 4.65629 7.35228Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_221_3706">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                        </svg>
                    </a>
    
                </div>
            
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add BOE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Autocomplete
                    style={{  width:'80%',margin: "auto" }}
                    freeSolo
                    autoComplete
                    autoHighlight
                    options={myOptions}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        onChange={getDataFromAPI}
                        label="Search Properties"
                        id='search'
                        name="search"
                        inputRef={input => {
                            inputRef = input;
                            }}
                            
                        />
                    )}
                    />
                    <Button size="sm" style={{ margin: "20px",float:'right' }}
                        onClick={() => {
                            getapiId(inputRef);
                            }}
                    
                    >
                    Add 
                    </Button>
                    <EmptyItem 
                                colSpan={2}
                            />
                    <form onSubmit={handleSubmit}>
                        
                    <FormControl style={{ margin:'20px' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel id="demo-simple-select-standard-label"> BOE Type</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" defaultValue={"Elementary Schools"} id="boetype" variant="filled" color="success" focused name="boetype" label="Boe Type" fullWidth>
                                            <MenuItem disabled value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            <MenuItem value={"Elementary Schools"} selected>Elementary Schools</MenuItem>
                                            <MenuItem value={"High Schools"} >High Schools</MenuItem>
                                            <MenuItem value={"Middle Schools"}>Middle Schools</MenuItem>
                                            <MenuItem value={"Other Buildings"}>Other Buildings</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Travelers Loc #" variant="filled"  focused  id="traveler_loc" name="traveler_loc" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Name" variant="filled"  focused  id="name" name="name" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Acct Loc #" variant="filled"  focused  id="acct_loc" name="acct_loc" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Address" variant="filled"  focused value={pradd} id="propertyaddress" name="propertyaddress" fullWidth inputProps={{ readOnly: true, }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Building ISO Fire Const. Code" variant="filled"  focused  id="building_iso_code" name="building_iso_code" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Stories" variant="filled"  focused  id="stories" name="stories" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Building Occupancy" variant="filled"  focused  id="occupancy" name="occupancy" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Building Sprinkler Prot." variant="filled"  focused  id="sprinkler_prot" name="sprinkler_prot" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Sq.Footage" variant="filled"  focused value={prarea} id="areasf" name="areasf" fullWidth inputProps={{ readOnly: true, }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Year Built" variant="filled"  focused  id="year_built" name="year_built" fullWidth />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Trended Building Value" variant="filled"  focused  id="trend_building_value" name="trend_building_value" fullWidth />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Trended BPP Value" variant="filled"  focused  id="trend_bpp_value" name="trend_bpp_value" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="EDP Value" variant="filled"  focused  id="edp_value" name="edp_value" fullWidth />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Vehicles Value" variant="filled"  focused  id="vehicle_value" name="vehicle_value" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Misc. Property Value" variant="filled"  focused  id="misc_value" name="misc_value" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="M & E Value" variant="filled"  focused  id="me_value" name="me_value" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ textAlign:'right'}}>
                                        <Button  variant="light" onClick={handleClose} size="sm" style={{ marginRight:'20px'}}>
                                            Close
                                        </Button>
                                        <Button type="submit" variant="dark"  size="sm">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
                    </form>

                </Modal.Body>
            </Modal>

            <DataGrid
                keyExpr="boeid"
                className={'dx-card wide-card'}
                dataSource={dataList}
                showRowLines={false}
                showBorders={true}
                columnAutoWidth={true}
                loadPanel={true}
                onRowClick={onRowClick}
                allowColumnReordering={true}
                allowColumnResizing={true} 
                rowAlternationEnabled={true}
                onExporting={onExporting}
                onSaving={onSaving}
                >
                <Editing mode="popup" onClick={()=> {console.log("Something clicked")}}  allowUpdating={true} allowDeleting={true} allowEditing={true} />
                <ColumnFixing enabled={true} />
                <Paging defaultPageSize={25} />
                <Pager showPageSizeSelector={true} showInfo={true} allowedPageSizes={[5, 10, 25, 50, 100, 'all']} />
                <Export enabled={true} formats={exportFormats} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <GroupPanel visible={true} />
                
                
                
                <Column dataField={'traveler_loc'} caption={'Travelers Loc #'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'name'} caption={'Name'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'acct_loc'} caption={'Acct Loc #'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'propertyaddress'} caption={'Address'} dataType={String} allowEditing={false} cellRender={(data)=>{
                    return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)
                    
                }}/>
                
                <Column dataField={'boetype'} caption={'BOE Type'} groupIndex={0} />
                <Column dataField={'city'} caption={'City'} allowEditing={false} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'state'} caption={'State'} allowEditing={false} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'zip'} caption={'ZIP+4'} allowEditing={false} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'building_iso_code'} caption={'Building ISO Fire Const. Code'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'stories'} caption={'Stories'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'occupancy'} caption={'Building Occupancy'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'sprinkler_prot'} caption={'Building Sprinkler Prot.'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'areasf'} caption={'Sq. Footage'} dataType={Number} format={'#,###'} allowEditing={false} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}} />
                <Column dataField={'year_built'} caption={'Year Built'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                {object.map(function(objects){
                                return <Column caption={objects.year}> 
                                        <Column allowEditing={false}  dataField={objects.year+'trend_building_value'} caption={objects.year+' Trended Building Value'} dataType={Number} format={'currency'}
                                         cellRender={(data)=>{ 
                                            let Tr_b = data.data.trend_building_value
                                            
                                            object.map(function(obj){
                                                if(objects.year >= obj.year){ Tr_b=Tr_b*obj.value1}                                                
                                            })
                                            
                                            return (<>${(Math.round(Tr_b )|| 0).toLocaleString()}</>)
                                            }}  />
                                        <Column allowEditing={false}  dataField={objects.year+'trend_bpp_value'} caption={objects.year+' Trended BPP Value'} dataType={Number} format={'currency'}
                                            cellRender={(data)=>{ 
                                                let Tr_bb = data.data.trend_bpp_value
                                                
                                                object.map(function(obj){
                                                    if(objects.year >= obj.year){ Tr_bb=Tr_bb*obj.value2}                                                
                                                })
                                                
                                                return (<>${(Math.round(Tr_bb) || 0).toLocaleString()}</>)
                                                }} />
                                       </Column>;
                                
                })}
                             
                <Column dataField={'edp_value'} caption={'EDP Value'} dataType={Number} format={'currency'} />      
                <Column dataField={'vehicle_value'} caption={'Vehicles Value'} dataType={Number} format={'currency'} />              
                <Column dataField={'misc_value'} caption={'Misc. Property Value'} dataType={Number} format={'currency'} />              
                <Column dataField={'me_value'} caption={'M & E Value'} dataType={Number} format={'currency'} />              
                <Column dataField={'Total_value'}  caption={'Total Value'} allowEditing={false} 
                
                cellRender={(data)=>{ 
                    let Tr_b = data.data.trend_building_value
                    let Tr_bb = data.data.trend_bpp_value
                    
                    object.map(function(obj){
                        Tr_bb=Tr_bb*obj.value2  
                        Tr_b=Tr_b*obj.value1                                             
                    })

                    let Total_value = Tr_b+Tr_bb+data.data.edp_value+data.data.misc_value+data.data.me_value+data.data.vehicle_value
                    
                    return (<>${(Math.round(Total_value )|| 0).toLocaleString()}</>)
                    }} dataType={Number} format={curFormat} 
                />             
                
                
            </DataGrid>


            <Modal
            size="xl"
            show={modalShow} onHide={modClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                BOE Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{width:'100%'}}>
                <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Location Details</div>
                                        <div className='info-details'>
                                            <Grid container spacing={2}>                                               

                                                
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>BOE Type</div>
                                                        <div className='info-record-value'>{infoDetail.boetype}</div>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Travelers Loc #</div>
                                                        <div className='info-record-value'>{infoDetail.traveler_loc}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Name</div>
                                                        <div className='info-record-value'>{infoDetail.name}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Acct Loc #</div>
                                                        <div className='info-record-value'>{infoDetail.acct_loc}</div>
                                                    </Grid>
                                            
                                                
                                            </Grid>
                                            
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Contact Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                              
                                        
                                        <Grid item xs={12} md={3}>
                                            <div className='info-record-label'>Address</div>
                                            <div className='info-record-value'>{infoDetail.propertyaddress}</div>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <div className='info-record-label'>City</div>
                                            <div className='info-record-value'>{infoDetail.city}</div>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <div className='info-record-label'>State</div>
                                            <div className='info-record-value'>{infoDetail.state}</div>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <div className='info-record-label'>ZIP+4</div>
                                            <div className='info-record-value'>{infoDetail.zip}</div>
                                        </Grid>
                                        </Grid>
                                            
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Other Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                                
                                        <Grid item xs={12} md={3}>
                                                            <div className='info-record-label'>Building ISO Fire Const. Code</div>
                                                            <div className='info-record-value'>{infoDetail.building_iso_code}</div>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <div className='info-record-label'>Stories</div>
                                                            <div className='info-record-value'>{infoDetail.stories}</div>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <div className='info-record-label'>Building Occupancy</div>
                                                            <div className='info-record-value'>{infoDetail.occupancy}</div>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <div className='info-record-label'>Building Sprinkler Prot.</div>
                                                            <div className='info-record-value'>{infoDetail.sprinkler_prot}</div>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <div className='info-record-label'>Sq. Footage</div>
                                                            <div className='info-record-value'>{infoDetail.areasf}</div>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <div className='info-record-label'>Year Built</div>
                                                            <div className='info-record-value'>{infoDetail.year_built}</div>
                                                        </Grid>
                                            </Grid>

                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Trended Value Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                                    

                                        {object.map(function(objects){

                                            let Trend_b = infoDetail.trend_building_value
                                            let Trend_bb = infoDetail.trend_bpp_value

                                            object.map(function(obj){
                                                if(obj.year <= objects.year){
                                                    Trend_bb=Trend_bb*obj.value2  
                                                    Trend_b=Trend_b*obj.value1 
                                                }                                                                                            
                                            })

                                            return <><br/><Grid item xs={12} md={3}>
                                                <div className='info-record-label'>{objects.year} Trended Building Value</div>
                                                <div className='info-record-value'>${(Math.round(Trend_b)|| 0).toLocaleString()}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>{objects.year} Trended BPP Value</div>
                                                <div className='info-record-value'>${(Math.round(Trend_bb)|| 0).toLocaleString()}</div>
                                            </Grid></>
                                            })}


                                                </Grid>
                                            
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Total  Value Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                            
                                            
                                        <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>EDP Value</div>
                                                        <div className='info-record-value'>${(Math.round(infoDetail.edp_value)|| 0).toLocaleString()}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Vehicles Value</div>
                                                        <div className='info-record-value'>${(Math.round(infoDetail.vehicle_value)|| 0).toLocaleString()}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Misc. Property Value</div>
                                                        <div className='info-record-value'>${(Math.round(infoDetail.misc_value)|| 0).toLocaleString()}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>M & E Value</div>
                                                        <div className='info-record-value'>${(Math.round(infoDetail.me_value)|| 0).toLocaleString()}</div>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Total Value</div>
                                                        <div className='info-record-value'>${(Math.round(infoDetail.total+infoDetail.edp_value+infoDetail.misc_value+infoDetail.me_value+infoDetail.vehicle_value)|| 0).toLocaleString()}</div>
                                                    </Grid>
                                        </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            
                            {/* {(new Date().getFullYear())} */}
                           

                            </Grid>
                </Box>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modClose}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal
            size="md"
            show={perShow} onHide={performClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >                       
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    % year wise for Trended value
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{width:'100%'}}>
                   
                    <Percent service={"boe"}/>

                    
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" onClick={performCloseRefresh}>Close</Button>
                </Modal.Footer>
            </Modal>

            

            

          




                    

        </React.Fragment>

            </div>
        </div>
    )
}

export default BOEList;
